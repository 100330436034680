<!--<div class="not-found">
  <div class="not-found__container">
    <div class="not-found__photo">
      <img src="/assets/images/404.jpg" alt=""/>
    </div>

    <h1 class="not-found__title">
      Sorry, Page Not Found
    </h1>

    <div class="not-found__bottom">
      <a routerLink="/"
         class="not-found__button">
        Go to home page
      </a>
    </div>
  </div>
</div>-->

<div class="not-found">

  <div class="stars">

    <div class="central-body">
      <img
        class="image-404"
        src="/assets/images/decor/404.svg"
        width="300px">

      <a routerLink="/"
         class="btn-go-home">
        GO BACK HOME
      </a>

    </div>

    <div class="objects">
      <img
        class="object_rocket"
        src="/assets/images/decor/rocket.svg"
        alt=""
        width="40px">

      <div class="earth-moon">
        <img
          class="object_earth"
          src="/assets/images/decor/earth.svg"
          alt=""
          width="100px">

        <img
          class="object_moon"
          src="/assets/images/decor/moon.svg"
          alt=""
          width="80px">
      </div>

      <div class="box_astronaut">
        <img
          class="object_astronaut"
          src="/assets/images/decor/astronaut.svg"
          alt=""
          width="140px">
      </div>

    </div>

    <div class="glowing_stars">
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>

    </div>

  </div>

</div>
