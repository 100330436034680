import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ApexChart, ApexNonAxisChartSeries, ApexResponsive, ChartComponent, NgApexchartsModule} from "ng-apexcharts";

export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    responsive: ApexResponsive[];
    labels: any;
};


@Component({
    selector: 'app-simple-pie',
    standalone: true,
  imports: [CommonModule, NgApexchartsModule],
    templateUrl: './simple-pie.component.html',
    styleUrls: ['./simple-pie.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimplePieComponent {
    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;

    constructor() {
        this.chartOptions = {
            series: [7.47, 91.74],
            chart: {
                width: 380,
                type: "pie"
            },
            labels: ["Insurance#", "Travel#"],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                }
            ]
        };
    }
}
