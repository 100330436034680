/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserWithRelations } from './userWithRelations';


/**
 * (tsType: UsercontactsWithRelations, schemaOptions: { includeRelations: true })
 */
export interface UsercontactsWithRelations { 
    id?: number;
    name?: string;
    email?: string;
    telephone?: string;
    createdAt?: string;
    updatedAt?: string;
    registereduserid?: number;
    belongstouser?: number;
    user?: UserWithRelations;
    belongTo?: UserWithRelations;
}

