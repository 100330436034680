<div class="donate_container">
  <img class="donate_animation" src="assets/images/donate_coffee.gif" alt="Donate Animation"/>
  <div class="notice_wrapper">
    <p class="notice"><span class="_title">Important Notice:</span> We are building our platform. Please support us -
      buy a coffee.
    </p>
    <a class="donate_link" href="https://www.buymeacoffee.com/fedir"
       target="_blank">https://www.buymeacoffee.com/fedir</a>
  </div>
  <div class="wrap_button">
    <a href="https://www.buymeacoffee.com/fedir" target="_blank">
      <img class="donate_button" src="assets/images/donate.png" alt="donate img"/>
    </a>
  </div>
  <mat-icon class="close_icon" (click)="close()">close</mat-icon>
</div>
