/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * (tsType: Omit<Userteamrelation, \'id\' | \'teamId\'>, schemaOptions: { title: \'NewMemberInUserteams\', exclude: [ \'id\', \'teamId\' ] })
 */
export interface NewMemberInUserteams { 
    isactive?: boolean;
    isNotificationSent?: boolean | null;
    interfaceProcessFlag?: string;
    hasaccesstodata?: boolean;
    role?: string;
    createdAt?: string;
    updatedAt?: string;
    userId?: number;
    contactId?: number;
}

