/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * (tsType: Partial<Pnldata>, schemaOptions: { partial: true })
 */
export interface PnldataPartial { 
    id?: number;
    factdate?: string | null;
    isdeleted?: number | null;
    itemcode?: string | null;
    referencenumber?: string | null;
    documentreference?: string | null;
    ideaid?: number;
    teamId?: number | null;
    createdbyid?: number;
    updatedbyid?: number;
    sponsorid?: number;
    sponsorname?: string | null;
    approved?: boolean | null;
    debtreturned?: boolean | null;
    factvalue?: number | null;
    valueofmoneybusinessplan?: string | null;
    currency?: string | null;
    spentbyid?: number | null;
    isdonation?: boolean | null;
    quantity?: number | null;
    uom?: string | null;
    description?: string | null;
    itemtovalue?: string | null;
    basevalueofmoney?: number | null;
    basecurrecy?: string | null;
    ratetobase?: number | null;
    typeofdata?: string;
    pnlrow?: string;
    itemname?: string | null;
    orderid?: string | null;
    customerName?: string | null;
    CustomerPhone?: string | null;
    CustomerAddress?: string | null;
    Comment?: string | null;
    vendorid?: number;
    vendorname?: string | null;
    Success?: string | null;
    InvestmentStatus?: string | null;
    CostCenter?: string | null;
    company?: string | null;
    datalabel?: string | null;
    factpnlaccount?: string | null;
    createdAt?: string;
    updatedAt?: string;
    interfaceProcessFlag?: string;
}

