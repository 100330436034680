/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserteamrelationScopeFilter } from './userteamrelationScopeFilter';


export interface UserteamrelationIncludeFilterItems { 
    relation?: UserteamrelationIncludeFilterItems.RelationEnum;
    scope?: UserteamrelationScopeFilter;
}
export namespace UserteamrelationIncludeFilterItems {
    export type RelationEnum = 'team' | 'user' | 'contact';
    export const RelationEnum = {
        Team: 'team' as RelationEnum,
        User: 'user' as RelationEnum,
        Contact: 'contact' as RelationEnum
    };
}


