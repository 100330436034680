<div class="container" style="width: fit-content;">
    <mat-form-field appearance="outline">
        <mat-label>UserName</mat-label>
        <input matInput [(ngModel)]="userName">
    </mat-form-field>
    <mat-form-field appearance="outline" class="ml-2">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="email">
    </mat-form-field><br>
    <mat-form-field appearance="outline">
        <mat-label>Displayas</mat-label>
        <input matInput [(ngModel)]="displayas">
    </mat-form-field><br>
    <button mat-raised-button color="primary" (click)="submit()">Submit</button>
    <button mat-raised-button color="warn" class="ml-3" (click)="closeDialog()">Close</button>
</div>