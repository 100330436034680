/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserScopeFilter } from './userScopeFilter';


export interface UserIncludeFilterItems { 
    relation?: UserIncludeFilterItems.RelationEnum;
    scope?: UserScopeFilter;
}
export namespace UserIncludeFilterItems {
    export type RelationEnum = 'userCredentials' | 'comments' | 'ownedTeams' | 'teams' | 'contacts';
    export const RelationEnum = {
        UserCredentials: 'userCredentials' as RelationEnum,
        Comments: 'comments' as RelationEnum,
        OwnedTeams: 'ownedTeams' as RelationEnum,
        Teams: 'teams' as RelationEnum,
        Contacts: 'contacts' as RelationEnum
    };
}


