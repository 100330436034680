/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * (tsType: MdmpnlrowsWithRelations, schemaOptions: { includeRelations: true })
 */
export interface MdmpnlrowsWithRelations { 
    typeofchoa?: string;
    active?: boolean;
    startupid?: number;
    startupname?: string;
    pnlcode?: string;
    pnlRow: string;
    level0?: string;
    level1?: string;
    level2?: string;
    level3?: string;
    level4?: string;
}

