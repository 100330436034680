/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * (tsType: Omit<Userteamrelation, \'id\' | \'teamId\' | \'createdAt\' | \'updatedAt\'>, schemaOptions: { title: \'NewMembersInUserteams\', exclude: [ \'id\', \'teamId\', \'createdAt\', \'updatedAt\' ] })
 */
export interface NewMembersInUserteams { 
    isactive?: boolean;
    isNotificationSent?: boolean | null;
    interfaceProcessFlag?: string;
    hasaccesstodata?: boolean;
    role?: string;
    userId?: number;
    contactId?: number;
}

