export const environment = {
  envname:'sandbox',
  production: false,
  baseUrl: 'https://api-backend-sbx.wlasnasprawa.com',
  fileDynamicPlaceholder: '##sPlatformFileStorage##/',
  fileDynamicPlaceholderUrl: 'https://api-backend-sbx.wlasnasprawa.com/files/',
  autologinTestUser: true,
  ImportantNoticeEnabled:true,
  autologinTestUserCredentials: {username:'john@doe.com', password:'opensesame' },
  environmentBadgeLabel: "sandbox",
  environmentBadgeDisplay: true
};
