/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdeastartuplistScopeFilter } from './ideastartuplistScopeFilter';


export interface IdeastartuplistIncludeFilterItems { 
    relation?: IdeastartuplistIncludeFilterItems.RelationEnum;
    scope?: IdeastartuplistScopeFilter;
}
export namespace IdeastartuplistIncludeFilterItems {
    export type RelationEnum = 'comments' | 'createdByProfile' | 'updatedByProfile';
    export const RelationEnum = {
        Comments: 'comments' as RelationEnum,
        CreatedByProfile: 'createdByProfile' as RelationEnum,
        UpdatedByProfile: 'updatedByProfile' as RelationEnum
    };
}


