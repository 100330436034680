<div class="login">

  <div class="login__block">

    <form
      nz-form
      *ngIf="!isResetPassword && !isEmailSent"
      [formGroup]="emailForm"
      (ngSubmit)="onEmailSubmit()"
      class="login__form">

      <h3
        nz-typography
        class="text-center">
        Reset Password
      </h3>

      <nz-form-item>
        <nz-form-label>Email</nz-form-label>
        <nz-form-control>

          <input
            #email
            nz-input
            formControlName="email"
            placeholder="Email"
            type="email"/>

        </nz-form-control>
      </nz-form-item>

      <button
        nz-button
        type="submit"
        nzType="primary"
        nzBlock>
        Send Email
      </button>

      <div class="errors-list" *ngIf="errors?.length>0">
        <div class="error-item" *ngFor="let error of errors">
          <div class="name">{{error.name}}</div>
          <div class="message">{{error.message}}</div>
        </div>
      </div>

    </form>

    <form
      nz-form
      *ngIf="isResetPassword"
      [formGroup]="resetForm"
      (ngSubmit)="onResetSubmit()"
      class="login__form">

      <h3
        nz-typography
        class="text-center">
        Reset Password
      </h3>

      <nz-form-item>
        <nz-form-label>New Password</nz-form-label>
        <nz-form-control>

          <input
            nz-input
            #password
            formControlName="password"
            placeholder="New Password"
            type="password"/>

        </nz-form-control>
      </nz-form-item>

      <button
        nz-button
        type="submit"
        nzType="primary"
        nzBlock>
        Reset Password
      </button>

      <div class="errors-list" *ngIf="errors?.length>0">
        <div class="error-item" *ngFor="let error of errors">
          <div class="name">{{error.name}}</div>
          <div class="message">{{error.message}}</div>
        </div>
      </div>

    </form>

    <p
      *ngIf="isEmailSent"
      class="reset__text">
      Email has been sent to {{emailForm.value.email}}
    </p>

  </div>

</div>

