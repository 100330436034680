/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserteamrelationWithRelations } from './userteamrelationWithRelations';
import { UserWithRelations } from './userWithRelations';


/**
 * (tsType: UserteamsWithRelations, schemaOptions: { includeRelations: true })
 */
export interface UserteamsWithRelations { 
    id?: number;
    teamname?: string;
    realm?: string;
    username?: string;
    commentmemo?: string;
    location?: string;
    picture?: string;
    isactive?: boolean;
    isdeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    teamOwnerId?: number;
    members?: Array<UserteamrelationWithRelations>;
    teamOwner?: UserWithRelations;
}

