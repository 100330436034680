/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Ideastartuplist { 
    id?: number;
    settingsIndustry?: string;
    settingsCategory?: string;
    commentsDisabled?: boolean;
    estimatedBudget?: number;
    estimatedBudgetCurrecy?: string;
    estimatedTeamSize?: number;
    estimatedTimeInMonths?: number;
    ideadescription?: string;
    ideatitle?: string;
    ispublic?: boolean;
    startupsize?: string;
    parentcode?: number;
    recordtype?: string;
    completeddate?: string;
    isactive?: boolean;
    ideabusinessplansetup?: string;
    ideabusinessplansetup_draft?: string;
    status?: string;
    location?: string;
    revisionCode?: string;
    image?: string;
    ytdstatistics?: string;
    interfaceProcessFlag?: string;
    _protected?: boolean;
    createdAt?: string;
    updatedAt?: string;
    createdbyid?: number;
    updatedbyid?: number;
    isdeleted?: number | null;
    sharedteams?: number;
}

