/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UsercontactsScopeFilter } from './usercontactsScopeFilter';


export interface UsercontactsIncludeFilterItems { 
    relation?: UsercontactsIncludeFilterItems.RelationEnum;
    scope?: UsercontactsScopeFilter;
}
export namespace UsercontactsIncludeFilterItems {
    export type RelationEnum = 'user' | 'belongTo';
    export const RelationEnum = {
        User: 'user' as RelationEnum,
        BelongTo: 'belongTo' as RelationEnum
    };
}


