/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserWithRelations } from './userWithRelations';


/**
 * (tsType: CommentsWithRelations, schemaOptions: { includeRelations: true })
 */
export interface CommentsWithRelations { 
    id?: number;
    commenttext?: string | null;
    ideaid?: number;
    commentdatetime?: string;
    isNotificationSent?: boolean | null;
    isdeleted?: number | null;
    interfaceProcessFlag?: string;
    feedbackrank?: number;
    createdAt?: string;
    updatedAt?: string;
    userprofileId?: number;
    userprofile?: UserWithRelations;
}

