export * from './commentsController.service';
import { CommentsControllerService } from './commentsController.service';
export * from './commentsUserController.service';
import { CommentsUserControllerService } from './commentsUserController.service';
export * from './filesController.service';
import { FilesControllerService } from './filesController.service';
export * from './ideastartuplistCommentsController.service';
import { IdeastartuplistCommentsControllerService } from './ideastartuplistCommentsController.service';
export * from './ideastartuplistController.service';
import { IdeastartuplistControllerService } from './ideastartuplistController.service';
export * from './ideastartuplistUserController.service';
import { IdeastartuplistUserControllerService } from './ideastartuplistUserController.service';
export * from './mdmpnlrowsController.service';
import { MdmpnlrowsControllerService } from './mdmpnlrowsController.service';
export * from './notificationsController.service';
import { NotificationsControllerService } from './notificationsController.service';
export * from './pingController.service';
import { PingControllerService } from './pingController.service';
export * from './plantemplatesController.service';
import { PlantemplatesControllerService } from './plantemplatesController.service';
export * from './pnldataController.service';
import { PnldataControllerService } from './pnldataController.service';
export * from './userCommentsController.service';
import { UserCommentsControllerService } from './userCommentsController.service';
export * from './userContactsController.service';
import { UserContactsControllerService } from './userContactsController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './userOwnTeamsController.service';
import { UserOwnTeamsControllerService } from './userOwnTeamsController.service';
export * from './userTeamMembersController.service';
import { UserTeamMembersControllerService } from './userTeamMembersController.service';
export * from './userTeamsController.service';
import { UserTeamsControllerService } from './userTeamsController.service';
export * from './usercontactController.service';
import { UsercontactControllerService } from './usercontactController.service';
export * from './userteamController.service';
import { UserteamControllerService } from './userteamController.service';
export * from './userteamrelationController.service';
import { UserteamrelationControllerService } from './userteamrelationController.service';
export const APIS = [CommentsControllerService, CommentsUserControllerService, FilesControllerService, IdeastartuplistCommentsControllerService, IdeastartuplistControllerService, IdeastartuplistUserControllerService, MdmpnlrowsControllerService, NotificationsControllerService, PingControllerService, PlantemplatesControllerService, PnldataControllerService, UserCommentsControllerService, UserContactsControllerService, UserControllerService, UserOwnTeamsControllerService, UserTeamMembersControllerService, UserTeamsControllerService, UsercontactControllerService, UserteamControllerService, UserteamrelationControllerService];
