/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PnldataScopeFilter } from './pnldataScopeFilter';


export interface PnldataIncludeFilterItems { 
    relation?: PnldataIncludeFilterItems.RelationEnum;
    scope?: PnldataScopeFilter;
}
export namespace PnldataIncludeFilterItems {
    export type RelationEnum = 'ideaRecord' | 'teamProfile' | 'createdByProfile' | 'updatedByProfile' | 'sponsorProfile' | 'mdmPnlRowRecord' | 'vendorProfile';
    export const RelationEnum = {
        IdeaRecord: 'ideaRecord' as RelationEnum,
        TeamProfile: 'teamProfile' as RelationEnum,
        CreatedByProfile: 'createdByProfile' as RelationEnum,
        UpdatedByProfile: 'updatedByProfile' as RelationEnum,
        SponsorProfile: 'sponsorProfile' as RelationEnum,
        MdmPnlRowRecord: 'mdmPnlRowRecord' as RelationEnum,
        VendorProfile: 'vendorProfile' as RelationEnum
    };
}


