/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserteamrelationIncludeFilterItems } from './userteamrelationIncludeFilterItems';


export interface UserteamrelationFilter { 
    offset?: number;
    limit?: number;
    skip?: number;
    order?: string | Array<string>;
    fields?: object | Set<string>;
    include?: Array<UserteamrelationIncludeFilterItems | string>;
}

