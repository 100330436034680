export let MENU_ITEM = [
  {
    path: 'all-publicideas',
    title: 'menu.publicIdeas',
    icon: 'edit'
  },
  {
    path: 'my-items',
    title: 'menu.myItems',
    icon: 'edit',
  },
  {
    path: 'my-teams',
    title: 'menu.myTeams',
    icon: 'team',
  },
  {
    path: 'profile',
    title: 'menu.profile',
    icon: 'user'
  },
  {
    path: 'admin-tools',
    title: 'menu.adminTools',
    icon: 'build'
  },
  {
    path: 'contact-us',
    title: 'menu.helpAndContactUs',
    icon: 'user'
  },
];
