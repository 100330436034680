import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {LoaderService} from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(private loaderService: LoaderService) {};

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.params.has('skipLoader') && req.params.get('skipLoader') === 'true') {
            return next.handle(req);
        }
        this.showLoader();

        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.onEnd();
                }
            },
            (err: any) => {
                this.onEnd();
            }));
    };

    private onEnd(): void {
        this.hideLoader();
    };

    private showLoader(): void {
        this.loaderService.show();
    };

    private hideLoader(): void {
        this.loaderService.hide();
    };
}
