import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexFill,
    ApexYAxis,
    ApexTooltip,
    ApexTitleSubtitle,
    NgApexchartsModule,
    ApexDataLabels,
    ApexPlotOptions,
    ApexLegend,
    ApexStates,
    ApexGrid,
} from "ng-apexcharts";

type ApexXAxis = {
    type?: "category" | "datetime" | "numeric";
    categories?: any;
    labels?: {
        style?: {
            colors?: string | string[];
            fontSize?: string;
        };
    };
};

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis | ApexYAxis[];
    title: ApexTitleSubtitle;
    labels: string[];
    stroke: any; // ApexStroke;
    dataLabels: any; // ApexDataLabels;
    fill: ApexFill;
    tooltip: ApexTooltip;

};

@Component({
    selector: 'app-column-with-data-labels',
    standalone: true,
    imports: [CommonModule, NgApexchartsModule],
    templateUrl: './column-with-data-labels.component.html',
    styleUrls: ['./column-with-data-labels.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnWithDataLabelsComponent {
    @ViewChild("chart") chart: ChartComponent;
    @Input() title: string;
    @Input() chartOptions: Partial<any>;

    constructor() {

    }
}
