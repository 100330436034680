/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserteamsScopeFilter } from './userteamsScopeFilter';


export interface UserteamsIncludeFilterItems { 
    relation?: UserteamsIncludeFilterItems.RelationEnum;
    scope?: UserteamsScopeFilter;
}
export namespace UserteamsIncludeFilterItems {
    export type RelationEnum = 'members' | 'teamOwner';
    export const RelationEnum = {
        Members: 'members' as RelationEnum,
        TeamOwner: 'teamOwner' as RelationEnum
    };
}


