<div class="my-5">
  <div class="row ">

    <div class="col-3"></div>
    <div class="col">

      <div class="card text-center text-white card-form p-4">
        <div class="card-body">
          <h3>Add an idea</h3>
          <p class="my-3">Please fill out this form </p>
          <form>
            <div class="form-group">
              <input type="text" class="form-control form-control-lg" placeholder="Idea Title">
            </div>
            <div class="form-group">
              <input type="email" class="form-control form-control-lg" placeholder="Email">
            </div>

            <div class="form-group">
              <textarea class="form-control form-control-lg" placeholder="Description of a dream" rows="15"></textarea>
            </div>

            <input (click)="submitNewIdeaOrdream('title','description')" value="Submit" class="btn btn-lg my-3 btn-outline-light btn-block">
          </form>
        </div>
      </div>
    </div>
    <div class="col-3"></div>
  </div>

  </div>
