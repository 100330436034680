/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Userteams { 
    id?: number;
    teamname?: string;
    realm?: string;
    username?: string;
    commentmemo?: string;
    location?: string;
    picture?: string;
    isactive?: boolean;
    isdeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    teamOwnerId?: number;
}

