<div *ngIf="size === 'sm'" class="progress-sm">
  <span class="progress-percent" *ngIf="percent&&percentShow" [ngStyle]="{'left':percent-3 + '%'}">{{percent}}%</span>
  <div class="progress">
    <div [class]="themeColor" [ngStyle]="{'width':percent + '%'}">
    </div>
  </div>
</div>

<div *ngIf="size === 'lg'" class="progress-lg">
  <div class="progress">
    <div [class]="themeColor" [ngStyle]="{'width':percent + '%'}">
      <span class="progress-percent" *ngIf="percent&&percentShow">{{percent}}%</span>
    </div>
  </div>
</div>
