/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserteamsWithRelations } from './userteamsWithRelations';
import { UsercontactsWithRelations } from './usercontactsWithRelations';
import { UserWithRelations } from './userWithRelations';


/**
 * (tsType: UserteamrelationWithRelations, schemaOptions: { includeRelations: true })
 */
export interface UserteamrelationWithRelations { 
    id?: number;
    isactive?: boolean;
    isNotificationSent?: boolean | null;
    interfaceProcessFlag?: string;
    hasaccesstodata?: boolean;
    role?: string;
    createdAt?: string;
    updatedAt?: string;
    teamId?: number;
    userId?: number;
    contactId?: number;
    team?: UserteamsWithRelations;
    user?: UserWithRelations;
    contact?: UsercontactsWithRelations;
}

