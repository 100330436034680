<div style="display: flex; justify-content: center; align-items: center; margin-top: 40px  ">
    <h3 style="color: #007bbd"><i>Net Sales Revenue by Product Category Brand</i></h3>
</div>
<div id="chart">
    <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [labels]="chartOptions.labels"
            [responsive]="chartOptions.responsive"
    ></apx-chart>
</div>
