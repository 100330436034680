<div class="tree">
  <ul>
    <li *ngFor="let item of model" [ngClass]="{'children-list':isChild}">
      <div class="trees-items" (click)="toggleItem(item)">
        <div class="file-ico">
          <i class="fa fa-file fa-fw ico" *ngIf="!item.children"></i>
          <i class="fa fa-caret-right fa-fw ico" *ngIf="item.children&&(item.toggle === 'off'||item.toggle === 'init')"></i>
          <i class="fa fa-folder fa-fw ico" *ngIf="item.children&&(item.toggle === 'off'||item.toggle === 'init')"></i>

          <i class="fa fa-caret-down fa-fw ico" *ngIf="item.children&&item.toggle === 'on'"></i>
          <i class="fa fa-folder-open fa-fw ico" *ngIf="item.children&&item.toggle === 'on'"></i>
        </div>
        <span>{{item.name}}</span>
      </div>
      <div class="item-children" [@collapse]="item.toggle">
        <file-tree *ngIf="item.children" [model]="item.children" isChild="true"></file-tree>
      </div>
    </li>
  </ul>
</div>
