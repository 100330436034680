export const environment = {
  envname:'production',
  production: true,
  baseUrl: 'https://api.wlasnasprawa.com',
  fileDynamicPlaceholder: '##sPlatformFileStorage##/',
  fileDynamicPlaceholderUrl: 'https://api.wlasnasprawa.com/files/',
  autologinTestUser: false,
  ImportantNoticeEnabled:true,
  autologinTestUserCredentials: {},
  environmentBadgeLabel: "mvp",
  environmentBadgeDisplay: false
};
