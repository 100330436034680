/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * (tsType: Omit<User, \'id\'>, schemaOptions: { title: \'NewUserProfile\', exclude: [ \'id\' ] })
 */
export interface NewUserProfile { 
    realm?: string;
    username?: string;
    email: string;
    phone?: string;
    password?: string;
    emailVerified?: boolean;
    verificationToken?: string;
    sandboxuser?: boolean;
    inactive?: boolean;
    isdeleted?: boolean;
    teamownerid?: number;
    belongstoteams?: string;
    teammembers?: string;
    userbadge?: string;
    commentmemo?: string;
    location?: string;
    language_locale?: string;
    picture?: string;
    createdAt?: string;
    updatedAt?: string;
    personalcontacts?: string;
    lastSuccessfulLoginAt?: string;
    userrole?: string;
    usersubscription?: string;
    userConsent?: string;
}

