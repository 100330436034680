/**
 * sbackend-lb4
 * sbackend lb4
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: fedir.indutnyi@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * (tsType: Omit<Notifications, \'id\'>, schemaOptions: { title: \'NewNotifications\', exclude: [ \'id\' ] })
 */
export interface NewNotifications { 
    notificationtext?: string | null;
    recordtype?: string;
    isRead?: boolean | null;
    ideaid?: number;
    teamid?: number;
    isDeleted?: boolean | null;
    notificationdatetime?: string;
    createdAt?: string;
    updatedAt?: string;
    interfaceProcessFlag?: string;
    userprofileId?: number;
}

