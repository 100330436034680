import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    ViewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WebdatarocksPivotModule} from "@webdatarocks/ngx-webdatarocks";
import * as jspreadsheet from 'jspreadsheet-ce';
import {CellValue, Column} from 'jspreadsheet-ce';


@Component({
    selector: 'app-plate',
    standalone: true,
    imports: [CommonModule, WebdatarocksPivotModule],
    templateUrl: './plate.component.html',
    styleUrls: ['./plate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlateComponent implements AfterViewInit , OnChanges {
    @ViewChild('spreadsheet', {static: true}) spreadsheet: ElementRef;
    @Input() tableTitle: string;
    @Input() data: CellValue[][] | Record<string, CellValue>[];
    @Input() columns: Column[];
    @Input() setStyle: Record<string, string | string[]>;
    @Input() calculateTotals;

    ngAfterViewInit() {
        const table = jspreadsheet(this.spreadsheet.nativeElement, {
            data: this.data,
            columns: this.columns,
            // minDimensions: [6, 8],
            updateTable: (instance, cell, col, row, val, label, cellName) => {
                if (row > 0 && col === 6) {
                    cell.classList.add('total-cell');
                }
            },
            onload: () => {
                // Дочекайтеся завершення ініціалізації таблиці
                this.adjustColumnTextAlignment();
            }
        });
        this.setStyle && table.setStyle(this.setStyle);
        console.log(this.calculateTotals, 555555)
        if(this.calculateTotals) this.calculateTotals(table);


    }

    calculateAverageMargin3(table: any, colIndex: number) {
        const rows = table.getData();
        let sum = 0;
        let count = 0;
        rows.forEach(row => {
            if (row[colIndex] !== undefined && row[colIndex] !== '') {
                sum += parseFloat(row[colIndex]);
                count++;
            }
        });
        return (sum / count).toFixed(1) + '%';
    }

    calculateColumnSum3(table: any, colIndex: number) {
        let sum = 0;
        const rows = table.getData();
        rows.forEach(row => {
            sum += +row[colIndex];
        });
        return sum;
    }

    adjustColumnTextAlignment() {
        // Застосовуємо стиль до кожної клітинки в першій колонці
        this.spreadsheet.nativeElement.querySelectorAll('td:nth-child(2)').forEach(cell => {
            cell.style.textAlign = 'left';
        });
    }

    ngOnChanges(changes: any): void {
        console.log(changes, 88888)
    }


    // getColumnsConfig(): { type: 'text' | 'numeric'; title: string; width?: number, minWidth?: number }[] {
    //     return [
    //         { type: 'text', title: ' ', width: 200, minWidth: 100 },
    //         { type: 'numeric', title: '2024', width: 120, minWidth: 80 },
    //         { type: 'numeric', title: '2025', width: 120, minWidth: 80 },
    //         { type: 'numeric', title: '2026', width: 120, minWidth: 80 },
    //         { type: 'numeric', title: '2027', width: 120, minWidth: 80 },
    //         { type: 'numeric', title: '2028', width: 120, minWidth: 80 },
    //         { type: 'numeric', title: 'Total', width: 120, minWidth: 80 }
    //     ];
    // }


    // generateData() {
    //     return [
    //         ['Volume', 1000, 1100, 1200, 1300, 1400, 0],
    //         ['Net Sales Revenue', 50000, 55000, 60000, 65000, 70000, 0],
    //         ['Gross to Net, %', 20, 21, 22, 23, 24, 0],
    //         ['Gross Profit', 20000, 22000, 24000, 26000, 28000, 0],
    //         ['Gross to Margin %', 40, 41, 42, 43, 44, 0],
    //         ['Operating Profit EBIT', 10000, 11000, 12000, 13000, 14000, 0],
    //         ['Operating Profit Margin', 20, 21, 22, 23, 24, 0],
    //         ['EBIT to Net Sales, %', 20, 20, 20, 20, 20, 0]
    //     ].map(row => {
    //         const total = row.slice(1, -1).reduce((acc, value) => +acc + +value, 0);
    //         row[row.length - 1] = total;
    //         return row;
    //     });
    // }
}
